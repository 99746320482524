.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1;
}

.App-footer {
  background-color: #282c34;
  color: white;
  padding: 10px 0;
  flex-shrink: 0;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}
