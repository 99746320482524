.birthdate-generator {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c34;
  color: white;
  overflow: hidden;
}

h2 {
  margin-bottom: 20px;
}

.card {
  background: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
  margin-bottom: 20px;
}

.output-card {
  width: 300px;
  text-align: center;
  margin-top: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.output {
  margin-top: 20px;
  text-align: left;
  max-height: 200px;
  overflow-y: auto;
}

.horizontal-slider {
  width: 100%;
  height: 10px;
  margin-top: 10px;
}

.thumb {
  height: 15px;
  width: 15px;
  background-color: #007bff;
  cursor: grab;
  border-radius: 50%;
}

.track {
  top: 0;
  bottom: 0;
  background: #ddd;
  border-radius: 999px;
}

.track-1 {
  background: #007bff;
}
